<template>
  <div class="product-page">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="container">
      <div class="product-block-title">
        Myn Bala
      </div>
      <div class="product-block-desc">
        Олимпиада «Мың бала» - проект национального масштаба, ориентированный на поддержку и развитие школьников 5-х и 6-х классов в сельской местности по всей стране.
      </div>
<!--      <div class="product-img-djoon-1" :style="{ 'background-image': `url('/css/images/DJOON-1.png')` }">-->

<!--      </div>-->
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/mynbala-back1.png"
          fit="fit"></el-image>

      <div class="product-block-desc">
        Проект представляет собой платформу для проведения олимпиады
        с автоматической оценкой результатов теста, уведомлениями
        и последующей рассылкой сертификатов и дипломов.
      </div>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/mynbala-back2.png"
          fit="fit"></el-image>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/mynbala-back3.png"
          fit="fit"></el-image>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/mynbala-back4.png"
          fit="fit"></el-image>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/mynbala-back5.png"
          fit="fit"></el-image>
      <el-image
          style="width: 100%; height: 100%"
          src="/css/images/products/mynbala-back6.png"
          fit="fit"></el-image>


    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Products',
  data() {
    return {
      currentTab: "all",
      skills: [
        'Изучение основ продаж',
        'Формирование УТП',
        'Работа с воронками продаж',
        'Основы построения контакта с клиентом',
        'Навыки самопрезентации',
        'Презентация продукта/услуги',
        'Работа с CRM системой',
        'Работа с системой телефонии',
        'Основы переговоров',
        'Базы работы со скриптами разговоров',
        'Основа работы с холодными звонками',
        'Работа с возражениями',
      ],
      advantages: [
        'Без учителей',
        'Без домашних заданий',
        'Без дедлайнов',
        'Без вебинаров',
      ]
    }
  },
}
</script>
<style>

</style>
